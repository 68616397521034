@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
@mixin bluredImages($height:166px, $width:166px) {
    position: absolute;
    bottom: 20%;
    filter: blur(40px);
    width: $width;
    height: $height;
}

.bluredYellowDot {
    @include bluredImages();
    left: -5%;
}

.bluredBlueDot {
    @include bluredImages($height: 110px, $width: 80px);
    right: 0%;
}

.section3ImagesRow {
    position: relative;
}

.firstDivTitle {
    width: 399px;
    margin-bottom: 20px;
    h1 {
        font-size: 50px;
        font-family: GiloryBold;
        color: $primaryText;
    }
}

.firstDivDesc {
    margin-bottom: 31px;
    p {
        font-family: GiloryLight;
        font-size: 16px;
        color: $primaryDesc;
    }
}

.firstDivLink {
    a {
        text-decoration: none;
        font-size: 14px;
        font-family: GiloryMedium;
        color: $secondaryBlue;
    }
}

.successStroyEligBtn {
    @include btns.eligBtn($bgColor: $primaryYellow, $hoverBg: $primaryBlue);
}

.storiesSection2TitleHolder {
    h1 {
        font-size: 43px;
        font-family: GiloryBold;
        line-height: 58px;
        text-align: center;
    }
}

.succesStoriesBtnsHolder {
    margin-top: 25px;
}

.textLinkHolder {
    a {
        font-size: 14px;
        font-family: GiloryRegular;
        color: $primaryBlue;
        &:hover {
            color: $secondaryBlue;
        }
    }
}

.storiesSection2 {
    padding-bottom: 80px;
}

.succesStoriesBtnsHolder {
    width: 289px;
    justify-content: space-between;
}

.imgColHolder {
    display: flex;
    align-items: center;
}
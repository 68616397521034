@import '../../assets/sass-helpers/_colors';
@import '../../assets/sass-helpers/_fonts';
.orphansSattsContianer {
    background-color: $primaryBlue;
    height: 257px;
    border-radius: 45px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.orphansNumberHolder {
    p {
        font-family: GiloryBold;
        font-size: 50px;
        color: $primaryYellow;
    }
}

.orphansTitleHolder {
    p {
        font-family: GiloryBold;
        font-size: 17px;
        color: $white;
    }
}

.orphansDescHolder {
    p {
        font-family: GiloryLight;
        font-size: 16px;
        color: $white;
    }
}
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.storyDetailsTextHolder {
    pre {
        font-size: 18px;
        color: $primaryBlue;
        font-family: GiloryRegular;
        padding-top: 55px;
    }
}

.yellowBluredStory {
    position: absolute;
    right: -60px;
    bottom: 0;
    filter: blur(40px);
}

.storyAvatar {
    position: relative;
}

.storyTitleHolder {
    // height: 115px;
    background: $white;
    border-radius: 15px;
    width: 100%;
    padding: 25px;
    box-shadow: -2px 3px 19px $shadowPrimary;
    position: absolute;
    bottom: 0;
    p {
        font-family: GiloryBold;
        font-size: 52px;
        line-height: 64px;
    }
}

.storyThumbHolder {
    position: relative;
    margin-bottom: 90px;
}

.storyAvatar {
    width: 100%;
    height: auto;
    border-radius: 25px;
}
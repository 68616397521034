$primaryBlue: #16437B;
$secondaryBlue: #2A7DE1;
$thirdBlue: #A8CBFF;
$lightBlue: #E7EEFA;
$paymentBtnBlue:#F3F4FF;
$paymentBtnHover:#DEEBFB;
$bgBlue: #f7feff;
$white: #FFFFFF;
$shadowPrimary: #9A9A9AAB;
$primaryText:#0A1C37;
$bgLightGray: #F0F4F7;
$primaryDesc: #ABABB3;
$primaryYellow: #FFC629;
$secondaryYellow: #F5F0D0;
$primaryRed: #C81C1B;
$secondaryRed: #EEE0E0;
$inputBorderPrimary: #DCDDE6;
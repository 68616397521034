@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.impactBoxHolder {
    background: $primaryBlue;
    border-radius: 45px;
    width: 260px;
    height: 307px;
    margin: 0 auto;
    box-shadow: -2px 3px 19px $shadowPrimary;
    padding-top: 27px;
}

.impactBoxNumberHolder {
    p {
        font-family: GiloryBold;
        font-size: 36px;
        color: $primaryYellow;
        line-height: 64px;
    }
}

.impactBoxTitleHolder {
    h3 {
        font-family: GiloryBold;
        font-size: 20px;
        color: $white;
        line-height: 25px;
        text-align: center;
        margin-bottom: 10px !important;
    }
}

.impactBoxDescHolder {
    width: 245px;
    height: 38px;
    text-align: center;
    small {
        font-family: GiloryLight;
        font-size: 16px;
        color: $white;
        line-height: 19px;
    }
}
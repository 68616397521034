@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.circleContentHolder {
    height: 307px;
    width: 545px;
    justify-content: space-evenly;
    background-color: $white;
    border-radius: 25px;
    box-shadow: -2px 3px 19px $shadowPrimary;
    margin: 0 auto;
}

.circleTextHolder {
    width: 257px;
    p {
        font-family: GiloryMedium;
        font-size: 16px;
        color: $primaryText;
        line-height: 19px;
    }
}
    @import "../../assets/sass-helpers/_fonts";
    @import "../../assets/sass-helpers/_colors";
    .btnPayment {
        width: 100%;
        background-color: $paymentBtnBlue;
        height: 60px;
        border-radius: 16px;
        margin-top: 25px;
        &:hover,
        &:active {
            cursor: pointer;
            background-color: $paymentBtnHover !important;
            border: 2px solid $secondaryBlue !important;
            color: $secondaryBlue !important;
        }
    }
    
    .btnPaymentChecked {
        background-color: $paymentBtnHover;
        border: 2px solid $secondaryBlue;
        color: $secondaryBlue;
        width: 100%;
        height: 60px;
        border-radius: 16px;
        margin-top: 25px;
        &:hover,
        &:active {
            cursor: pointer;
            background-color: $paymentBtnHover !important;
            border: 2px solid $secondaryBlue !important;
            color: $secondaryBlue !important;
        }
    }
@import "../../assets/sass-helpers/_fonts";
@import "../../assets/sass-helpers/_colors";
.coverHolder {
    padding-top: 20px;
    margin-bottom: 20px;
    img {
        width: 100%;
        height: 80%;
    }
}

.newsDetailsTitle {
    width: 900px;
    h1 {
        font-family: GiloryBold;
        font-size: 52px;
        line-height: 64px;
        color: $primaryText;
        text-align: center;
    }
}

.newsDetailsDesc {
    width: 70%;
    padding-bottom: 40px;
    pre {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $primaryBlue;
    }
}
// @import url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.1.8/semantic.min.css");
.container {
    padding: 20px;
}

.failure-holder {
    background-color: #f6f4f4;
    font-family: 'Raleway', sans-serif;
    height: 100vh;
}

.teal {
    background-color: #ffc952 !important;
    color: #444444 !important;
}

.failure-form {
    a {
        color: #47b8e0 !important;
    }
}

.message {
    text-align: left;
}

.price1 {
    font-size: 40px;
    font-weight: 200;
    display: block;
    text-align: center;
}

.ui.message p {
    margin: 5px;
}
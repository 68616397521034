@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.menuItemsHolder {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  li {
    a {
      font-family: GiloryBold;
      text-decoration: none;
      color: $primaryBlue;
      font-size: 16px;
    }
    img {
      display: none;
    }
    &:hover {
      transition: all 0.4 ease;
      a {
        color: $secondaryBlue;
      }
      img {
        display: block !important;
      }
    }
  }
}

.menuItemsHolderAr {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  li {
    a {
      text-decoration: none;
      color: $primaryBlue;
      font-size: 16px;
      font-weight: 600;
    }
    img {
      display: none;
    }
    &:hover {
      transition: all 0.4 ease;
      a {
        color: $secondaryBlue;
      }
      img {
        display: block !important;
      }
    }
  }
}

.donateBtn {
  cursor: pointer;
  color: #ffffff;
  @include btns.donate;
  a {
    color: white !important;
    text-decoration: none;
  }
}

.donateBtnMobile {
  cursor: pointer;

  @include btns.donate;
  height: 40px !important;
  width: 77px !important;
  margin-left: auto;
  a {
    color: white !important;
    text-decoration: none;
  }
}

.mainNavLinkHolder {
  background-color: $secondaryBlue;
  height: 100vh;
  padding-top: 50px;
  margin-top: 5px;

  a {
    font-family: GiloryMedium;
    font-size: 22px;
    color: $white;
  }
}

// Arabic navbar items style
.mainNavLinkHolderAr {
  background-color: $secondaryBlue;
  height: 100vh;
  padding-top: 50px;
  margin-top: 5px;
  a {
    font-weight: 600;
    font-size: 22px;
    color: $white;
  }
}

.navbar-toggler-icon {
  background-image: url("../../assets/images/shared/burger-menu.svg") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// shadowHolder
@mixin dropBoxStandard($left, $height) {
  height: $height;
  width: 172px;
  background: $white;
  align-items: center;
  position: absolute;
  justify-content: center;
  border-radius: 36px;
  left: -35px;
  top: 39px;
  box-shadow: -2px 3px 19px $shadowPrimary;
  :first-child {
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
    color: $primaryText;
    &:hover {
      color: $secondaryBlue;
    }
  }
}

.menuDropBox {
  @include dropBoxStandard(-35px, 112px);
}

.menuDropBoxAr {
  @include dropBoxStandard(-60px, 112px);
}

.menuAboutDropBox {
  @include dropBoxStandard(-35px, 150px);
}

.menuAboutDropBoxAr {
  @include dropBoxStandard(-60px, 150px);
}

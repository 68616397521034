@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";

.azTitleSubTitleHolder {
  :first-child {
    font-family: GilorySemiBold;
    font-size: 36px;
    color: $primaryText;
    line-height: 48px;
  }
  :last-child {
    font-family: GiloryRegular;
    font-size: 18px;
    color: $primaryText;
    line-height: 24px;
  }
}

.formContainer {
  margin-bottom: 42px;
  background-color: $white;
}

.formToptext {
  p {
    font-family: GiloryBold;
    font-size: 18px;
    color: $primaryDesc;
    line-height: 24px;
  }
}

.btnSubmitHolder {
  margin-top: 30px;
  width: 80%;
  .btnSend {
    display: block;
    margin: 0 auto;
    @include btns.donate(
      $fontSize: 18px,
      $bgColor: $primaryYellow,
      $hoverBg: $primaryYellow
    );
  }
}

.customDonationInput {
  input {
    background-color: $paymentBtnBlue;
  }
  ::placeholder {
    text-align: center;
  }
  /* or, for legacy browsers */
  ::-webkit-input-placeholder {
    text-align: center;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }
  :-ms-input-placeholder {
    text-align: center;
  }
}

@import "../../assets/sass-helpers/_fonts";
@import "../../assets/sass-helpers/_colors";
$Adheight: 100%;
@mixin admissionMain($height, $width:260px) {
    background: white;
    height: 100%;
    align-items: center;
    border-radius: 25px;
    width: $width;
    position: relative;
    z-index: 3;
    display: flex;
    box-shadow: -2px 0px 33px $shadowPrimary;
}

@mixin admissionMainTablet($height, $width:260px) {
    background: white;
    height: 100%;
    align-items: center;
    border-radius: 25px;
    width: $width;
    position: relative;
    z-index: 3;
    display: flex;
    box-shadow: -2px 0px 33px $shadowPrimary;
    text-align: center;
}

.admissionRequiremntHolderLeft {
    @include admissionMain($height: 306px, $width: 270px);
}

.admissionRequiremntHolderTablet {
    @include admissionMainTablet($height: 100%, $width: "auto");
}

// .admissionRequiremntHolderRight {
//     @include admissionMain($height: 416px, $width: 277px);
// }
.iconHolder {
    margin: 41px 0;
}

.admissionTitleHolder {
    padding: 0 16px 16px 16px;
    text-align: center;
    h2 {
        font-family: GiloryBold;
        font-size: 20px;
        color: $primaryText;
        width: 225px;
    }
}

.admissionDescHolder {
    text-align: center;
    padding: 0 16px 16px 16px;
    p {
        font-family: GiloryLight;
        font-size: 16px;
        line-height: 19px;
        color: $primaryDesc;
        width: 225px;
    }
}
@import "../../assets/sass-helpers/_fonts";
@import "../../assets/sass-helpers/_colors";
.titleSubTitleHolder {
     :first-child {
        font-family: GilorySemiBold;
        font-size: 36px;
        color: $primaryText;
        line-height: 48px;
    }
     :last-child {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $primaryText;
        line-height: 24px;
    }
}

.homeLinkHolder {
    margin-top: 20px;
    a {
        text-decoration: none;
        color: $primaryBlue;
        &:hover {
            color: $secondaryBlue;
        }
    }
}
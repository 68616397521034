@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.mainContainer {
    align-items: center;
    justify-content: center;
    padding: 0;
}

.numberHolder {
    margin-right: 30px;
    p {
        font-size: 52px;
        font-family: GiloryBold;
        color: $primaryYellow;
    }
}

.numberHolderAr {
    margin-left: 30px;
    p {
        font-size: 52px;
        font-family: GiloryBold;
        color: $primaryYellow;
    }
}

.titleHolder {
    h2 {
        font-family: GiloryBold;
        font-size: 17px;
        color: white;
    }
}

.descTitle {
    p {
        font-family: GiloryLight;
        font-size: 16px;
        color: $thirdBlue;
    }
}
@import "../../assets/sass-helpers/_colors";

.JobsPage {
  text-transform: capitalize;
  .JobsTitleHolder {
    padding: 10px;
    padding-left: 0;
  }
  ul {
    margin-top: 10px;
    list-style-type: none;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    padding: 0;
    li {
      background-color: $paymentBtnBlue;
      padding: 10px;
      margin-bottom: 15px;
      border-radius: 8px;

      div {
        display: flex;
        flex-direction: column;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        border: 0;
        outline: 0;
        background-color: none;
        padding: 10px;
        border-radius: 8px;
        background-color: $secondaryBlue;
        color: $paymentBtnBlue;
        text-transform: capitalize;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 600px) {
  .JobsPage {
    ul {
      font-size: 12px !important;
    }
  }
}

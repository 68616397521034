@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.footerHolder {
    background-color: $primaryText;
    padding-top: 59px;
    color: white;
}

.yellowBtnDonate {
    @include btns.donate($fontSize: 18px, $bgColor: $primaryYellow, $hoverBg: $primaryYellow);
    color: $primaryText;
    text-decoration: none
}

// Left Side
.logoHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 73%;
}

.leftSideTitleHolder {
    margin-bottom: 20px;
    h1 {
        font-family: GilorySemiBold;
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 0;
    }
}

.leftSideDescHolder {
    width: 316px;
    margin-bottom: 20px;
    p {
        font-size: 18px;
        font-family: GiloryRegular;
        color: $thirdBlue;
    }
}

// Middle Side
.MiddleContentHolder {
    h3 {
        font-size: 16px;
        font-family: GiloryBold;
    }
    p {
        font-family: GiloryLight;
        font-size: 16px;
    }
}

.MidTitleHolder {
    margin-bottom: 40px;
    h3 {
        font-size: 20px;
    }
}

.workingHoursHolder {
    margin-bottom: 30px;
}

.addressTitleHolder {
    margin-bottom: 15px;
}

pre {
    white-space: pre-wrap;
}

.addressHolder {
    margin-bottom: 30px;
    pre {
        margin-bottom: 0;
        font-family: GiloryLight;
        font-size: 16px;
        overflow: hidden;
        line-height: 1;
    }
}

// Right Side
.RightSideTitleHolder {
    margin-bottom: 40PX;
    h3 {
        font-size: 20px;
        font-family: GiloryBold;
    }
}

.paragraphHolder {
    width: 281px;
    pre {
        font-size: 16px !important;
        font-family: GiloryLight !important;
    }
}

.copyRightTxt {
    color: white;
    font-size: 14px;
    font-family: GiloryMedium;
    text-align: center;
}
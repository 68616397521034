@import "../assets/sass-helpers/_colors";
.layoutMain {
  padding-left: 0;
  padding-right: 0;
  background: $bgBlue;
}

.layoutMainNoPadding {
  padding-left: 0;
  padding-right: 0;
  background: $bgBlue;
}

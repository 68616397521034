@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.cardTitle {
    font-family: GiloryBold;
    font-size: 20px;
    color: $primaryText;
}

.cardDesc {
    font-family: GiloryLight;
    font-size: 16px;
    line-height: 19px;
    color: $primaryDesc;
}

.card {
    border: 0;
    background: transparent;
}

.cardBody {
    background-color: $bgLightGray;
    width: 100%;
    border-radius: 16px;
    position: relative;
    top: -90px;
}

a {
    text-decoration: none;
}
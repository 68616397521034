@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.programsContainer {
    margin-bottom: 75px;
}

.programThumbHolder {
    padding: 10px;
}

.programMainCard {
    background: $bgLightGray !important;
    border-radius: 25px;
    border: 0;
    margin-top: 35px;
    height: 100%;
    img {
        border-radius: 25px;
    }
}

.programTitleCard {
    font-size: 20px;
    font-family: GiloryBold;
    line-height: 25px;
    color: $primaryText;
}

.programCardDesc {
    font-size: 16px;
    font-family: GiloryBold;
    line-height: 19px;
    color: $primaryDesc;
    width: 523px;
    padding-bottom: 35px;
    display: contents;
}

.programCardBody {
    margin: 0 auto;
}

.OurProgramsTitleHolder {
    h1 {
        font-family: GiloryBold;
    }
}
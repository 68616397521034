@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: $thirdBlue;
  border-radius: 25px;
  width: 277px !important;
  a {
    text-decoration: none;
  }
}

.card-slider-image {
  padding: 10px;
  height: 200px;
  width: 100%;
  object-fit: fill;
  border-radius: 25px;
}

.swiperCardBody {
  padding: 10px;
  height: 157px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card-main {
  background: $thirdBlue;
  border: 0;
  border-radius: 25px;
  border: 1px solid $white;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.card-body-text {
  font-size: 18px;
  font-family: GiloryBold;
  color: $primaryText;
}

.date-text-holder {
  small {
    font-size: 16px;
    color: white;
    font-family: GiloryRegular;
  }
}

.news-slug {
  text-decoration: none;
}
@media (max-width: 768px) {
  .swiper-slide {
    margin-right: 5px !important;
    width: 100% !important;
  }
}

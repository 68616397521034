@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.ImageHolder {
    background-image: url("../../assets/images/eligibility-media/blue-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    img {
        display: block;
        margin: 0 auto;
    }
}

.eligibilityTitleHolder {
    margin-top: 65px;
    margin-bottom: 35px;
    h1 {
        font-family: GiloryBold;
        font-size: 52px;
        line-height: 64px;
        color: $primaryText;
    }
}

.eligibilityDescHolder {
    color: $primaryBlue;
    font-family: GiloryRegular;
    font-size: 18px;
    p {
        margin-bottom: 32px;
        width: 488px;
    }
    pre {
        font-family: GiloryRegular;
        font-size: 18px;
    }
}

.section1RightSideHolder {
    margin-top: 65px;
}

// Section 2
.eligSection2 {
    background: $bgLightGray;
    padding-top: 48px;
    h1 {
        font-family: GiloryBold;
        font-size: 30px;
        line-height: 58px;
        color: $primaryText;
    }
    pre {
        color: $primaryBlue;
        font-size: 18px;
        font-family: GiloryRegular;
    }
}

.LeftSideTitleHolder {
    h1 {
        font-size: 54px;
        line-height: 64px;
        padding-top: 10px;
    }
}

// Section 3
.eligSection3 {
    padding-top: 35px;
    padding-bottom: 101px;
}

.section3TitleHolder {
    margin-bottom: 35px;
    text-align: center;
    h1 {
        font-family: GiloryBold;
        font-size: 32px;
        line-height: 42px;
        color: $primaryText;
    }
}

.blueDotBehind {
    position: absolute;
    top: -22px;
    right: 0;
    filter: blur(35px);
    left: 0;
    margin: auto;
}

.bluredYellow {
    position: absolute;
    bottom: -45px;
    left: -8%;
    filter: blur(35px);
    height: 100%;
    width: 15%;
}

.rightBlueDotBehind {
    filter: blur(35px);
    position: absolute;
    right: 0;
    height: 100%;
    width: 127px;
}

// Ecxternal Links
.donateBtn {
    @include btns.donate;
    a {
        color: white !important;
        text-decoration: none;
    }
}

.externalLinkHolder {
    align-items: center;
    width: 30%;
    margin: 0 auto;
}

// Mobile
.donateBtnRes {
    @include btns.donate;
    background-color: $primaryYellow;
    a {
        color: $white !important;
    }
}
@import "../../assets/sass-helpers/_fonts";
@import "../../assets/sass-helpers/_colors";
.supportBox {
    height: 100%;
    width: 100%;
    // background-color: $white;
    border-radius: 45px;
    // box-shadow: -2px 3px 19px $shadowPrimary;
    margin-bottom: 25px;
}

.supportIconHolder {
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.supportTitleHolder {
    margin-bottom: 15px;
    h3 {
        font-family: GiloryBold;
        font-size: 19px;
        color: $primaryBlue;
        line-height: 24px;
        text-align: center;
    }
}

.supportDescHolder {
    width: auto;
    text-align: center;
    p {
        font-family: GiloryBold;
        font-size: 12px;
        color: $primaryBlue;
        line-height: 19px;
        opacity: 0.75;
        padding: 10px;
    }
}
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.formLeftSideHolder {
    background-image: url('../../assets/images/forms-media/form-left-bg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 25px;
    color: $white;
    padding: 35px;
    width: 398px;
    // height: 681px;
}

.bodyTitleHolder {
    h1 {
        font-family: GilorySemiBold;
        font-size: 32px;
        line-height: 48px;
    }
}

.bodyDescHolder {
    width: 318px;
    p {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $thirdBlue;
        line-height: 24px;
    }
}

.formFooterHolder {
    background-image: url('../../assets/images/forms-media/form-footer-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 25px;
}

.footerTextHolder {
    width: 278px;
    margin-bottom: 25px;
    p {
        font-family: GiloryRegular;
        font-size: 18px;
        line-height: 24px;
    }
}

.footerSponerHolder {
    height: 40px;
    img {
        width: 37px;
        height: 37px;
        margin-right: 15px;
    }
}

.sponsorDateHolder {
     :first-child {
        font-family: GiloryRegular;
        font-size: 18px;
        margin-bottom: 0;
    }
     :last-child {
        font-family: GiloryRegular;
        font-size: 14px;
        color: $thirdBlue;
        margin-bottom: 0;
    }
}
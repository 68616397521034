@import './_colors';
@import'./_fonts';
@mixin donate($fontSize:16px, $bgColor:$secondaryBlue, $hoverBg:$primaryBlue) {
    height: 64px;
    width: 150px;
    display: flex;
    align-items: center;
    background-color: $bgColor;
    justify-content: center;
    border-radius: 32px;
    font-size: $fontSize;
    font-weight: bold;
    &:hover {
        background-color: $primaryBlue;
        color: $white;
        font-weight: bold;
    }
}

@mixin formStandard($height:60px, $width:"auto") {
    height: $height !important;
    width: $width !important;
    border-radius: 16px !important;
    border-color: $inputBorderPrimary !important;
}

@mixin labelStandard {
    font-family: GiloryRegular;
    font-size: 18px;
    color: $primaryBlue;
    line-height: 24px;
    margin-bottom: 9px;
}

@mixin eligBtn($fontSize:18px, $bgColor:$secondaryBlue, $hoverBg:$primaryBlue) {
    height: 56px;
    width: 198px;
    display: flex;
    align-items: center;
    background-color: $bgColor;
    justify-content: center;
    border-radius: 28px;
    font-size: $fontSize;
    color: $primaryText;
    text-decoration: none;
    font-weight: bold;
    &:hover {
        background-color: $primaryBlue;
        color: $white;
        font-weight: bold;
    }
}
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.impactSection1 {
    // background-image: url("../../assets/images/our-impact-media/bg-header-lines.svg");
    // background-position: top;
    // background-repeat: no-repeat;
    // background-size: contain;
    position: relative;
    padding-bottom: 81px;
    // height: auto;
}

.section1Header {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 71px;
    h1 {
        font-family: GiloryBold;
        font-size: 106px;
        color: $secondaryBlue;
        line-height: 131px;
    }
    p {
        font-family: GiloryBold;
        font-size: 32px;
        color: $secondaryBlue;
        line-height: 58px;
        margin-bottom: 0;
    }
}

.section1Title {
    text-align: center;
    margin-bottom: 35px;
    h1 {
        font-family: GiloryBold;
        font-size: 52px;
        color: $primaryText;
        line-height: 64px;
    }
}

.section1Desc {
    width: 618px;
    text-align: center;
    margin: 0 auto;
    small {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $primaryBlue;
        line-height: 24px;
    }
}

.impactBlueDot {
    height: 127px;
    position: absolute;
    right: 0;
    bottom: 0;
    filter: blur(30px);
}

.impactYellowDot {
    filter: blur(47px);
    width: 120px;
}

// Section 2
.circleContainer {
    padding-top: 96px;
    padding-bottom: 39px;
}

// Section 3
.impactSection3 {
    padding-bottom: 71px;
}

// Section 4
.impactSection4 {
    margin-bottom: 47px;
}

.impactSection4Title {
    text-align: center;
    p {
        font-family: GiloryBold;
        font-size: 52px;
        line-height: 52px;
        color: $primaryText;
    }
}

// section 5
.impactSection5 {
    padding-bottom: 103px;
}

.rowTitleHolder {
    h1 {
        font-family: GiloryBold;
        font-size: 38px;
        margin-bottom: 30px !important;
    }
}
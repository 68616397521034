@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
@mixin boxStandard($height, $width, $borderColor, $bgColor) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 3px solid $borderColor;
    background: $bgColor;
    height: $height;
    border-radius: 25px;
    margin-bottom: 35px;
    width: $width;
}

@mixin boxWidth($width) {
    width: $width;
}

.yellowBox {
    @include boxStandard(192px, 277px, $primaryYellow, $secondaryYellow);
}

.blueBox {
    @include boxStandard(192px, 593px, $secondaryBlue, $lightBlue);
}

.redBox {
    @include boxStandard("auto", 909px, $primaryRed, $secondaryRed);
}

.boxTitleHolder {
    img {
        margin-right: 15px;
    }
    p {
        font-family: GiloryBold;
        font-size: 20px;
        line-height: 25px;
        color: $primaryText;
    }
}

.boxDescHolder {
    p {
        font-size: 16px;
        font-family: GiloryLight;
        color: $primaryText;
        margin-bottom: 45px;
    }
}

.redBoxTab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border: 3px solid #C81C1B;
    background: #EEE0E0;
    border-radius: 25px;
    margin-bottom: 35px;
    width: 670px;
    padding: 30px;
}

.boxDescHolderMobile {
    width: 80%
}

.boxTitleHolderMob {
    width: 80%;
    margin-top: 20px;
    img {
        margin-right: 15px;
    }
    p {
        font-family: GiloryBold;
        font-size: 20px;
        color: $primaryText;
    }
}

.BoxHolder {
    justify-content: center;
    margin-bottom: 25px;
    width: 90%;
}

.boxText {
    font-size: 31px;
    font-family: GiloryBold;
    color: #FFC629;
    margin-bottom: 0;
}

.dataText {
    margin-bottom: 0;
    color: #ffc629;
    font-family: GiloryLight;
    font-size: 18px;
}
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.sponsor-swiper {
    width: 100%;
    height: 100%;
}

.sponsor-swiper-mobile {
    width: 271px;
    height: 100%;
    img {
        width: 55% !important;
        height: 200px;
        object-fit: contain;
        display: block;
        // margin: 0 auto;
    }
}

.sponsor-swiper-slide {
    height: 100%;
    width: 100%;
    background: transparent !important;
    a {
        text-decoration: none;
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: contain;
        display: block;
        // margin: 0 auto;
    }
}
@font-face {
    font-family: "GiloryBlack";
    src: url('../fonts/Gilroy/Gilroy-Black.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryBlackItalic";
    src: url('../fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryBold";
    src: url('../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryBoldItalic";
    src: url('../fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryLight";
    src: url('../fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryMedium";
    src: url('../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryRegular";
    src: url('../fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "GilorySemiBold";
    src: url('../fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: "GiloryLightItalic";
    src: url('../fonts/Gilroy/Gilroy-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: "YellixBlack";
    src: url('../fonts/Yellix/Yellix-Black.ttf') format('truetype');
}

@font-face {
    font-family: "YellixBlackItalic";
    src: url('../fonts/Yellix/Yellix-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: "YellixBold";
    src: url('../fonts/Yellix/Yellix-Bold.ttf') format('truetype');
}

@font-face {
    font-family: "YellixBoldItalic";
    src: url('../fonts/Yellix/Yellix-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: "YellixLight";
    src: url('../fonts/Yellix/Yellix-Light.ttf') format('truetype');
}

@font-face {
    font-family: "YellixMedium";
    src: url('../fonts/Yellix/Yellix-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "YellixDemiBold";
    src: url('../fonts/Yellix/Yellix-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: "YellixRegular";
    src: url('../fonts/Yellix/Yellix-Regular.ttf') format('truetype');
}
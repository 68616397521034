@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.aboutSection1LeftCol {
    padding-top: 96px;
}

.aboutTitleHolder {
    margin-bottom: 20px;
    h1 {
        font-family: GiloryBold;
        font-size: 62px;
        line-height: 64px;
        color: $primaryText;
    }
}

.aboutSection1Desc {
    width: 488px;
    pre {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $primaryBlue;
        line-height: 24px;
    }
}

.aboutRightSideImageHolder {
    background-image: url('../../assets/images/about-media/yellow-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.aboutSection1RigtCol {
    padding-top: 90px;
}

// Section 2
.aboutSection2 {
    // background-image: url('../../assets/images/about-media/about-bg.svg');
    // background-size: contain;
    // background-position: bottom;
    // background-repeat: no-repeat;
}

.aboutSection2TitleHolder {
    margin-bottom: 70px;
    h1 {
        font-family: GiloryBold;
        font-size: 62px;
        line-height: 64px;
        text-align: center;
    }
}

.aboutSection2Row {
    background-color: #16437b;
    border-radius: 45px;
    padding: 36px;
}

// Section 3
.aboutSection3 {
    padding-top: 50px;
    padding-bottom: 60px;
    // background-image: url('../../assets/images/about-media/about-section3-blue-bg.png'), url("../../assets/images/about-media/heritage-about.png");
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;
    background: #3685e1;
    height: auto;
}

.section3TitleHolder {
    margin-bottom: 66px;
    h1 {
        font-family: GiloryBold;
        font-size: 58px;
        color: $white;
        line-height: 58px;
        text-align: center;
    }
}

.colTitleHolder {
    margin-bottom: 20px;
    h1 {
        font-family: GiloryBold;
        font-size: 31px;
        color: $white;
        line-height: 58px;
    }
}

.colDescHolder {
    p {
        font-family: GiloryLight;
        font-size: 16px;
        line-height: 19px;
        color: $thirdBlue;
    }
}

//Section 4
.aboutSection4 {
    padding-top: 75px;
    // background-image: url('../../assets/images/about-media/yellow-lines.svg');
    // background-position: bottom;
    // background-repeat: no-repeat;
    height: auto;
}

.section4TitleHolder {
    padding-bottom: 51px;
    h1 {
        font-family: GiloryBold;
        font-size: 58px;
        color: $primaryText;
        line-height: 58px;
        text-align: center;
    }
}

.section4DescHolder {
    margin-bottom: 75px;
}

// Section 5
.aboutSection5 {
    background-color: $bgLightGray;
    padding-bottom: 91px;
    margin-bottom: 71px;
}

.section5TitleHolder {
    padding-top: 91px;
    h1 {
        font-family: GiloryBold;
        font-size: 52px;
        color: $primaryText;
        line-height: 64px;
        text-align: center;
    }
}

.aboutContentHolder {
    padding-top: 69px;
}

.iconHolder {
    margin-bottom: 15px;
}

.aboutContentTitleHolder {
    h1 {
        font-family: GiloryBold;
        font-size: 30px;
        line-height: 58px;
        color: $primaryText;
        margin-bottom: 15px;
    }
}

.aboutContentDescHolder {
    width: 261px;
    p {
        font-family: GiloryLight;
        font-size: 16px;
        color: $primaryText;
        line-height: 19px;
    }
}
@import "../../assets/sass-helpers/_fonts";
@import "../../assets/sass-helpers/_colors";
.mainLink {
    text-decoration: none;
}

.mainProgramHolder {
    width: 270px;
    height: 435px;
    background-color: $bgLightGray;
    border-radius: 25px;
}

.thumbnailHolder {
    padding: 10px;
    align-self: center;
    img {
        width: 250px;
        border-radius: 25px;
    }
}

.titleHolder {
    p {
        font-size: 20px;
        color: $primaryText;
        font-family: GiloryBold;
        padding: 0 16px;
    }
}

.descHolder {
    p {
        font-family: GiloryLight;
        color: $primaryDesc;
        padding: 0 16px;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 19px;
    }
}
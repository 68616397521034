@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.titleHolder {
    margin-top: 65px;
    margin-bottom: 35px;
    h1 {
        font-family: GiloryBold;
        font-size: 52px;
        color: $primaryText;
        line-height: 64px;
    }
}

.descHolder {
    width: 488px;
    margin-bottom: 23px;
    p {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $primaryBlue;
        line-height: 24px;
    }
}

.socialMediaHolder {
    width: 192px;
    justify-content: space-between;
    margin-bottom: 65px;
}

.formTitle {
    font-family: GiloryBold;
    font-size: 18px;
    color: $primaryDesc;
    line-height: 24px;
}

.btnSend {
    @include btns.donate($fontSize: 18px, $bgColor: $primaryYellow, $hoverBg: $primaryYellow);
}

//   Conatct details
.contactInfoHolder {
    margin-top: 65px;
}

.firstInfo,
.secondInfo {
    margin-bottom: 25px;
}

.contactDetailsTitleHolder {
    padding-bottom: 7px;
    h4 {
        font-family: GiloryBold;
        font-size: 16px;
        color: $primaryBlue;
        line-height: 20px;
    }
}

.contactDetailsDescHolder {
    width: 281px;
    p {
        font-family: GiloryLight;
        font-size: 16px;
        color: $primaryBlue;
        line-height: 19px;
    }
}

.secondInfo {
    pre {
        font-family: GiloryLight;
        font-size: 16px;
        color: $primaryBlue;
        line-height: 19px;
    }
}

.contactFormRow {
    justify-content: center;
}
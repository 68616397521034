@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.mainLink {
    text-decoration: none;
}

.invCol {
    margin-bottom: 53px;
}

.mainTitle {
    font-family: GiloryBold;
    font-size: 52px;
    color: $primaryText;
    margin: 65px 0;
}

.imageCoverHolder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageCoverHolder img {
    max-height: 350px;
    width: 100%;
    object-fit: fill;
    border-radius: 15px;
}

.invCoverTitle {
    position: absolute;
    bottom: 0;
    p {
        font-size: 17px;
        font-family: GiloryBold;
        padding: 5px;
        color: $white;
        line-height: 25px;
        background: rgb(128, 128, 128, 0.6901960784);
        border-radius: 15px;
    }
}
@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
// Section 1
.container-fluid {
    padding: 0;
}

.Section1ContentHolder {
    position: relative;
    right: 42px;
}

.topLeftDotHolder {
    .topLeftImage {
        height: 127px;
        width: 127px;
        filter: blur(40px);
        position: relative;
        top: 110px;
    }
}

.btnDonate {
    @include btns.donate($fontSize: 18px);
    color: white;
    text-decoration: none;
}

.title {
    font-size: 59px;
    font-family: "GiloryBold";
}

.titleHolder {
    margin-bottom: 23px;
}

.bluetext {
    color: $secondaryBlue;
}

.Typewriter {
    display: inline !important;
}

.descHolder {
    margin-bottom: 19px;
    p {
        font-family: GiloryRegular;
        font-size: 18px;
        color: $primaryBlue;
    }
}

.linkHolder {
    margin-right: 33px;
    a {
        text-decoration: none;
        color: $primaryBlue;
        font-size: 18px;
        &:hover {
            color: $secondaryBlue;
        }
    }
}

// Cover section
.section1imagesMainHolder {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    position: relative;
    align-items: center;
    padding-bottom: 80px;
}

.bluredImager {
    filter: blur(30px);
    position: absolute;
    left: -15px;
    width: 127px;
    height: 127px
}

.secondImage {
    position: relative;
    left: 38px;
    height: 277px;
    width: 217px;
    border-radius: 25px;
    transform: rotate(-6deg);
}

.secondImageRes {
    position: relative;
    left: 38px;
    border-radius: 25px;
    transform: rotate(-6deg);
    width: 78%;
}

.mainCover {
    position: relative;
    display: flex;
    align-items: center;
}

.mainCoverImage {
    width: 569px;
    height: auto;
    border-radius: 25px;
}

.playerIconHolder {
    &:hover {
        cursor: pointer;
    }
}

.playIcon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    backdrop-filter: blur(18px);
    border-radius: 50%;
    z-index: 6;
}

.startIcon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 6;
}

.imagesRow {
    // background-image: url('../../assets/images/index-media/blue-lines-bg.svg');
    // height: auto;
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
}

// Section 3
.indexSection3 {
    background: $bgBlue;
}

// Success Stroy part
@mixin bluredImages($height:166px, $width:166px) {
    position: absolute;
    bottom: 20%;
    filter: blur(40px);
    width: $width;
    height: $height;
}

.bluredYellowDot {
    @include bluredImages();
    left: -15%;
}

.bluredBlueDot {
    @include bluredImages($height: 110px, $width: 80px);
    right: -1%;
}

.section3ImagesRow {
    position: relative;
}

.firstDivTitle {
    width: 399px;
    margin-bottom: 20px;
    h1 {
        font-size: 62px;
        font-family: GiloryBold;
        color: $primaryText;
    }
}

.firstDivDesc {
    margin-bottom: 31px;
    width: 65%;
    p {
        font-family: GiloryLight;
        font-size: 16px;
        color: $primaryDesc;
    }
}

.firstDivLink {
    a {
        text-decoration: none;
        font-size: 14px;
        font-family: GiloryMedium;
        color: $secondaryBlue;
    }
}

.cardTitle {
    font-family: GiloryBold;
    font-size: 20px;
    color: $primaryText;
}

.cardDesc {
    font-family: GiloryLight;
    font-size: 16px;
    line-height: 19px;
    color: $primaryDesc;
}

.card {
    border: 0;
    background: transparent;
}

.cardBody {
    background-color: white;
    width: 340px;
    padding-top: 35px;
    padding-left: 35px;
    border-radius: 16px;
    position: relative;
    top: -90px;
}

// Section 4 banner
.indexBanner2 {
    background-color: $secondaryBlue;
    height: auto;
}

.bgHolder {
    position: absolute;
    right: 0;
    z-index: 1;
}

.bannerContentHolder {
    position: relative;
    z-index: 3;
}

.bannerTitleHolder {
    margin-bottom: 35px;
    text-align: center;
    h1 {
        font-size: 53px;
        font-family: GiloryBold;
    }
}

.btnDonateYellow {
    @include btns.donate($fontSize: 18px, $bgColor: $primaryYellow, $hoverBg: $primaryYellow);
    color: $primaryText;
    text-decoration: none
}

.bannerLinkHolder {
    margin-right: 33px;
    a {
        text-decoration: none;
        color: $white;
        font-size: 18px;
        &:hover {
            color: $white;
            opacity: 0.75;
        }
    }
}

// Section 5 our programs
.main-row .col-lg-3 {
    padding: 0;
}

.programsSection {
    background: $bgBlue;
    padding-bottom: 75px;
}

.ourProgramTitle {
    font-size: 36px;
    font-family: GiloryBold;
    color: $primaryText;
}

.readMoreGray {
    color: $primaryDesc;
    font-size: 14px;
    font-family: GiloryMedium;
    text-decoration: none;
}

// Section 6
.section6 {
    background: $bgBlue;
    padding-bottom: 105px;
}

.invContainer {
    // background-color: white;
    position: relative;
    // border-radius: 50px;
    // padding-top: 35px;
    // padding-left: 35px;
    // box-shadow: 1px 5px 10px #9A9A9A;
}

.section6Header {
    p {
        font-size: 36px;
        font-family: GiloryBold;
        color: $primaryText;
    }
}

@mixin invText($paddingLeft:25px, $fontSize:16px) {
    color: white;
    font-size: $fontSize;
    font-family: GiloryBold;
    display: contents;
}

.mainFloatText {
    position: relative;
    bottom: 117px;
    background-color: #808080b0;
    border-radius: 15px;
    padding: 10px;
    p {
        @include invText($paddingLeft: 17px, $fontSize: 17px);
    }
}

.floatText {
    position: absolute;
    bottom: 0;
    background-color: #808080b0;
    border-radius: 15px;
    height: 35%;
    padding: 10px;
    p {
        @include invText($paddingLeft: 8px, $fontSize: 13px);
    }
}

.floatTextMobile {
    position: absolute;
    background-color: rgba(128, 128, 128, 0.6901960784);
    border-radius: 15px;
    padding: 10px;
    top: 0;
    bottom: 0;
    p {
        @include invText($paddingLeft: 8px, $fontSize: 13px);
    }
}

.readMeYellow {
    // background-image: url("../../assets/images/index-media/yellow-bg-thumb.png");
    // background-size: cover;
    // background-position: center;
    // height: 244px;
    // border-radius: 25px;
    // width: 219px;
    a {
        text-decoration: none;
        height: 244px;
    }
}

.qNewsCol {
    margin-bottom: 3px;
}

.qNewsLink {
    position: relative;
}

.invThumb {
    position: relative;
    img {
        width: 100%;
    }
}

.readMorefloatText {
    height: 244px;
    a {
        text-decoration: none;
    }
    p {
        color: $primaryText;
        font-family: GiloryMedium;
        font-size: 18px;
        font-weight: bold;
    }
}

.invBluredYellowTop {
    position: absolute;
    right: 37%;
    top: -43px;
    filter: blur(30px);
}

.invBluredYellowBottomLeft {
    position: absolute;
    bottom: 0;
    left: -60px;
    filter: blur(35px);
}

.invBlueDot {
    height: 241px;
    width: 90px;
    position: absolute;
    right: 0;
    bottom: 0;
    filter: blur(60px);
}

//section 8
.section8 {
    height: auto;
    background: $secondaryBlue;
    padding-top: 105px;
    // background-image: url("../../assets/images/shared/news-white-lines.svg");
    background-repeat: no-repeat;
    background-position: top right;
}

.mainCoverQueen {
    min-width: 100%;
    height: 90%;
    img {
        width: 100%;
    }
}

.mainQueenImage {
    width: 100%;
    min-height: 100%;
    height: 90%;
    min-width: 100%;
    object-fit: cover;
    border-radius: 26px;
}

.section8LinkHolder {
    a {
        text-decoration: none;
        color: white;
    }
}

.ssection8TitleHolder {
    p {
        font-size: 36px;
        font-family: GiloryBold;
        color: $white;
    }
}

.section8ReadMoreHolder {
    p {
        margin-right: 20px;
        font-size: 14px;
        font-family: GiloryMedium;
        color: $white;
    }
}

// Section 6
.section9 {
    padding-top: 136px;
    padding-bottom: 101px;
    background-color: $bgBlue;
}

.section9TitleHolder {
    margin-bottom: 56px;
    p {
        font-size: 36px;
        font-family: GiloryBold;
        color: $primaryText;
    }
}
.page-link {
  padding: 10px;
  cursor: pointer;
  color: #000000 !important;
}




.page-item.active .page-link {
  background-color: var(--bs-gray-400) !important;
  border-color: #f7feff !important;
}

.page-link:focus {
  box-shadow: none !important
}

.visually-hidden {
  display: none !important
}
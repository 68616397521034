@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.newsCol {
    margin-bottom: 30px;
}

.newsRouter {
    text-decoration: none;
    color: $primaryText;
}

.newsTitleHolder {
    margin-top: 65px;
    margin-bottom: 30px;
    h1 {
        font-family: GiloryBold;
        font-size: 52px;
        color: $primaryText;
    }
}

.newsItemHolder {
    background: $bgLightGray;
    border-radius: 25px;
    height: 314px;
    width: 382px;
}

.newsThumbHolder {
    padding: 10px;
    width: 100%;
}
@use "../../assets/sass-helpers/_btns" as btns;
@import "../../assets/sass-helpers/_colors";
@import "../../assets/sass-helpers/_fonts";
.formContainer {
    margin-bottom: 42px;
    background: $white;
}

.formToptext {
    p {
        font-family: GiloryBold;
        font-size: 18px;
        color: $primaryDesc;
        line-height: 24px;
    }
}

.btnSubmitHolder {
    margin-top: 21px;
    width: 80%;
    .btnSend {
        @include btns.donate($fontSize: 18px, $bgColor: $primaryYellow, $hoverBg: $primaryYellow);
    }
}

.yesBtn,
.noBtn {
    background: $white;
    width: 48%;
    color: $inputBorderPrimary;
    border: 1px solid $inputBorderPrimary;
    font-size: 18px;
    text-align: center;
    border-radius: 16px;
    margin-right: 10px;
    height: 60px;
    &:hover {
        color: $secondaryBlue !important;
        border: 2px solid $secondaryBlue !important;
        background: $white;
    }
}

.btnActive {
    color: $secondaryBlue !important;
    border: 2px solid $secondaryBlue !important;
}